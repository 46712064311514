import './bootstrap';

import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/brands';

import 'vanilla-cookieconsent/dist/cookieconsent.css'

import * as CookieConsent from "vanilla-cookieconsent";

$(function() {
    function formatState (state) {
        if (!state.id) {
            return state.text;
        }
        return $('<span style="color: ' + (state.element.style.color ? state.element.style.color : '#111') + '">' + state.text + '</span>');
    }

    $('.select2').select2({
        templateResult: formatState,
        templateSelection: formatState,
    });

    const menu = $("#menu");
    let bars = document.querySelectorAll('.bar');

    function toggleHamburger(e) {
        $(".hideable").toggleClass("sticky");
        bars.forEach(bar => bar.classList.toggle('x'));
    }

    menu.on('hide.bs.dropdown', function(e) {
        toggleHamburger(e);
    });
    menu.on('show.bs.dropdown', function(e) {
        toggleHamburger(e);
    });

    if (window.location.hash === "#embed") {
        $(".jumbotron").css("visibility", "hidden");
        $(".dblpromote").css("display", "none");
    }

    $(window).on('scroll', function(e) {
        const el = $(".savebox");
        if (el.length) {
            const p = el.offset().top;
            const jumbo = $(".jumbotron");
            if (p >= 70) {
                if (jumbo.is(":visible")) {
                    jumbo.hide();
                }
            } else {
                if (!jumbo.is(":visible")) {
                    jumbo.slideDown();
                }
            }
        }
    });
    $(window).trigger("scroll");
});
